import KM_photo from "../../../_assets/Testimonials/KMigala.png";
import KS_photo from "../../../_assets/Testimonials/KSochaczewska.png";
//import MK_photo from "../../../_assets/Testimonials/MartinKin.png";
//import HR_photo from "../../../_assets/Testimonials/HaniaRani.png";

export interface ITestimonals {
  title: string;
  name: string;
  profession: string;
  photo: string;
  description: string;
  nextPhoto: string;
}

export const testimonials: ITestimonals[] = [
  {
    title: "prof. dr hab. ",
    name: "Krzysztof Migała",
    profession: "Geographer and Polar Explorer",
    photo: KM_photo,
    nextPhoto: KS_photo,
    description: "testimonial_km",
  },

  {
    title: "",
    name: "Katarzyna Sochaczewska",
    profession: "Composer, Researcher and Sound Engineer",
    photo: KS_photo,
    //temporarily hidden
    //nextPhoto: MK_photo,
    nextPhoto: KM_photo,
    description: "testimonial_ks",
  },

  // temporarily hidden
  // {
  // title: "",
  // name: "Martin Kin",
  // profession: "Sport Photographer",
  // photo: MK_photo,
  // nextPhoto: HR_photo,
  // description: "testimonial_mk",
  // },

  // {
  //   title: "",
  //   name: "Hania Rani",
  //   profession: "Pianist and compose",
  //   photo: HR_photo,
  //   nextPhoto: KM_photo,
  //   description: "testimonial_hr",
  // },
];
