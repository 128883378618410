import { IData } from "../_helpers/models";

export const unavailableData: IData = {

  typeOfArea: [
    {
      name: "",
      subname: "",
      description: "",
      link: "",
      index: 0,
      locationX: "-1000%",
      locationY: "-1000%",
      positionTab: "areaGlobe__informations-center",
    },
  ],

  regions: [],

  panoramas: [],
};
