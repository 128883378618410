import React from "react";
import { useTerritoryContext } from "../../../../context/Terrritory.context";
import { useMediaQuery, useTheme, withStyles, Theme } from "@material-ui/core";

import { Dialog, DialogActions } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ExtraSound from "./ExtraSound";

export default function ExtraModal() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ExtrasDialog = withStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(2),
      width: "100vw",
      height: "100vh",
    },
  }))(Dialog);

  const { territoryData, activeTerritoryRoute } = useTerritoryContext();

  return (
    <>
      {territoryData.extras &&
        territoryData.extras.routeId === activeTerritoryRoute && (
          <div className="bigpanel__extras">
            <div className="bigpanel__extras-button" onClick={handleClickOpen}>
              <AddIcon className="bigpanel__extars-button-icon" /> Extras
            </div>

            <ExtrasDialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="extras"
              fullScreen={fullScreen}
              maxWidth={false}
            >
              <DialogActions>
                <div className="extras__close" onClick={handleClose}>
                  <CloseIcon
                    className="extras__close-icon"
                    style={{ fontSize: "28px" }}
                  />
                </div>
              </DialogActions>

              <div className="extras__dialog">
                <div className="extras__dialog-content">
                  <div className="extras__dialog-content-illustration">
                    <div className="extras__dialog-content-illustration-background">
                      <img src={territoryData.extras?.image} alt="extras" />
                    </div>
                  </div>
                  <div className="extras__dialog-content-text">
                    <p>{territoryData.extras?.description}</p>
                    <div className="extras__dialog-content-text-sound">
                      <ExtraSound />
                    </div>
                  </div>
                </div>
              </div>
            </ExtrasDialog>
          </div>
        )}
    </>
  );
}
