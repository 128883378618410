import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: '#000'

        },
        secondary: {
            main: '#fff',
            contrastText: '#000'
        }
    }
});