import React from "react";

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { useTerritoryContext } from "../../../../context/Terrritory.context";

import "react-h5-audio-player/lib/styles.css";

const ExtraSound1 = () => {
  const { territoryData } = useTerritoryContext();

  return (
    <div className="extraPlayer">
      <AudioPlayer
        autoPlay
        src={territoryData.extras?.sound}
        showJumpControls={false}
        showDownloadProgress={true}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        showFilledProgress={true}
        layout="horizontal-reverse"
        customProgressBarSection={[
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.CURRENT_TIME,
          <div className="rhap_separator">/</div>,
          RHAP_UI.DURATION,
        ]}
      />
    </div>
  );
};

export default ExtraSound1;
