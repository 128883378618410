import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swiper from "swiper";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import Testimonial from "./Testimonial";

import "swiper/css/swiper.css";
import { testimonials } from "./dataTestimonials";

const TestimonialsSlider = () => {
  const { t } = useTranslation();

  useEffect(() => {
    new Swiper(".testimonials__container", {
      pagination: {
        el: ".testimonials__pagination",
        clickable: true,
        bulletClass: "slider__bullet",
        bulletActiveClass: "slider__bullet--active",
        modifierClass: "slider__bullet--",
      },
      parallax: true,
      effect: "fade",
      loop: true,
      navigation: {
        nextEl: ".slider__button--next",
        prevEl: ".slider__button--prev",
      },
    });
  }, []);

  return (
    <div className="testimonials">
      <h2 className="testimonials__header">{t("testimonials")}</h2>
      <>
        <div className="testimonials__container swiper-container">
          <div className="testimonials__wrapper swiper-wrapper">
            {testimonials.map((testimonial, key, array) => (
              <div key={key} className="swiper-slide">
                <div className="slideTestimonial__imageMain--container">
                  <div className="slideTestimonial__imageMain">
                    <Testimonial data={testimonial} />
                  </div>
                </div>

                <div
                  className="slider__image--next testimonial__image--next"
                  data-swiper-parallax="-300"
                  style={{
                    backgroundImage: `url(${
                      array[key + 1] ? array[key + 1].photo : array[0].photo
                    })`,
                  }}
                ></div>
              </div>
            ))}
          </div>

          <div className="slider__buttons slider__buttons--vertical">
            <div className="slider__button slider__button--prev">
              <div className="slider__icon slider__icon--white">
                <ArrowBackIcon
                  style={{
                    display: "block",
                    color: "#fff",
                    fontSize: "34px",
                  }}
                />
              </div>
            </div>
            <div className="slider__button slider__button--next">
              <div className="slider__icon slider__icon--white">
                <ArrowForwardIcon
                  style={{
                    display: "block",
                    color: "#fff",
                    fontSize: "34px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="testimonials__paginationContainer">
            <div className="testimonials__pagination swiper-pagination"></div>
          </div>
        </div>
      </>
    </div>
  );
};

export default TestimonialsSlider;
