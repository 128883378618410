import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationEng from "./locales/en/translation.json";
import translationPol from "./locales/pl/translation.json";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: "en",

    keySeparator: false,
    nsSeparator: false,

    interpolation: {
      escapeValue: false
    },

    resources: {
      en: {
        translations: translationEng
      },
      pl: {
        translations: translationPol
      }
    },

    ns: ["translations"],
    defaultNS: "translations",
    react: {
      useSuspense: false
    }
  });

export default i18n;
