import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const { t, i18n } = useTranslation();
  const [openStatus, setOpenStatus] = useState(false);

  const toggleSubmenu = (state) => {
    setOpenStatus(state);
  };

  return (
    <>
      <div className="menu">
        <div className="menu__inside">
          <div className="menu__inside-links">
            <div className="menu__inside-links-link">
              <NavLink
                className="menu__inside-links-link-isDefault"
                exact={true}
                activeClassName="menu__inside-links-link-isActive"
                to="/"
              >
                Home
              </NavLink>
            </div>

            <div
              className="menu__inside-links-link"
              onMouseEnter={() => toggleSubmenu(true)}
              onMouseLeave={() => toggleSubmenu(false)}
            >
              <span className="menu__inside-links-link-isDefault">
                {t("projects")}
              </span>
              {openStatus && (
                <div className="menu__inside__submenu">
                  <NavLink
                    className="menu__inside-links-link-isDefault menu__inside__submenu__inside"
                    activeClassName="menu__inside__submenu__inside-isActive"
                    to="/arctic"
                  >
                    {t("soundscape")}
                  </NavLink>
                  <NavLink
                    className="menu__inside-links-link-isDefault menu__inside__submenu__inside"
                    activeClassName="menu__inside__submenu__inside-isActive"
                    to="/sound"
                  >
                    {t("sound")}
                  </NavLink>
                </div>
              )}
            </div>

            <div className="menu__inside-links-link">
              <NavLink
                className="menu__inside-links-link-isDefault"
                activeClassName="menu__inside-links-link-isActive"
                onClick={() => setOpenStatus(false)}
                to="/about"
              >
                {t("about")}
              </NavLink>
            </div>

            <div className="menu__inside-links-link">
              <NavLink
                className="menu__inside-links-link-isDefault"
                activeClassName="menu__inside-links-link-isActive"
                onClick={() => setOpenStatus(false)}
                to="/contact"
              >
                {t("contact")}
              </NavLink>
            </div>

            <div className="menu__inside-links-lang">
              <button
                className={`banner ${i18n.language === "pl" ? "active" : ""}`}
                onClick={() => i18n.changeLanguage("pl")}
              >
                pl
              </button>

              <div className="dot"></div>
              <button
                className={`banner ${i18n.language === "en" ? "active" : ""}`}
                onClick={() => i18n.changeLanguage("en")}
              >
                en
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
