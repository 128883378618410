import { withStyles } from "@material-ui/core/styles";
import { Tab } from "@material-ui/core";

export const StyledTab = withStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "15vw",
    minHeight: "30px",
    borderLeft: "2px solid #55BEC3",
    fontFamily: "Source Sans Pro",
    fontSize: "calc-rem(20px)",
    textTransform: "uppercase",
    letterSpacing: "0.14em",
    color: "#000F5A",

    "&:first-child": {
      borderLeft: "none",
    },

    "&:hover": {
      color: "#000F5A",
      opacity: 1,
    },
    "&$selected": {
      color: "#000F5A",
      icon: "",
    },
    "&:focus": {
      color: "#000F5A",
    },
  },
  selected: {},
})(Tab);

export default StyledTab;
