import React from "react";
import { useTranslation } from "react-i18next";

import { IPlace } from "../../../_helpers/models";

interface IProps {
  data: IPlace;
}

const Element = (props: IProps) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <div className="element">
      <div className="element__name">
        <h3 className={`${!data.isAvailable ? "element__name-coming" : ""}`}>{t(data.name)}</h3>
      </div>

      <div className="element__overlay">
        <img src={data.image} alt={data.name} />
        {!data.isAvailable && (
          <div className="element__overlay-content">
            <p>{t("coming_soon")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Element;
