import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Box, Typography } from "@material-ui/core";
import AreaGallery from "./AreaGallery";
import StyledTab from "./StyledTabs/StyledTab";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { StyledTabs } from "./StyledTabs/StyledTabs";
import { IRegions } from "../../../_helpers/models";
import AreaOption from "../AreaGlobe/AreaOption";
import { ArrowSelect, PinSelect } from "../../../_assets/areas";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabGallery(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabGallery.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

interface IProps {
  regions: IRegions[];
}

const AreaGalleryTab = (props: IProps) => {
  const { regions } = props;
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [visibleIcon, setVisibleIcon] = React.useState(1);
  const [textSelectInput, setTextSelectInput] = useState(regions[0].name);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [activeOption, setActiveOption] = useState(0);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
    setVisibleIcon(1);
  };

  const toggleSelect = useCallback(
    () => setIsOpenSelect((prevState) => !prevState),
    []
  );

  return (
    <>
      <div className="areaTabs">
        <div className="areaTabs__background"></div>
        <div className="areaTabs__content">
          <div className="areaTabs__content-tabs">
            <h2>{t("gallery")}</h2>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
            >
              {regions.map((region: IRegions, key) => (
                <StyledTab
                  key={key}
                  className="areaTabs__content-tabs-tab"
                  label={t(region.name)}
                  value={region.index}
                  icon={
                    <LocationOnIcon
                      style={{ opacity: visibleIcon }}
                      className="areaTabs__content-tabs-tab-icon"
                    />
                  }
                />
              ))}
            </StyledTabs>

            <div className="areaSelect" onClick={toggleSelect}>
              <div className="areaSelect__input">
                <img src={PinSelect} alt="pin" />
                <span>{t(textSelectInput)}</span>
                <img src={ArrowSelect} alt="select icon" />
              </div>

              {isOpenSelect && (
                <div className="areaSelect__options">
                  {regions?.map((region: IRegions, key: number) => (
                    <>
                      <p></p>
                      <AreaOption
                        region={region}
                        key={key}
                        handleSelectOption={() => {
                          setValue(region.index);
                          setTextSelectInput(t(region.name));
                          setActiveOption(region.index);
                        }}
                        selectedClassName={classnames(
                          activeOption === region.index
                            ? "areaSelect__options-option-selected"
                            : ""
                        )}
                      />
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>

          {regions.map((region, key) => (
            <TabGallery key={key} value={value} index={region.index}>
              <AreaGallery
                index={region.index}
                regions={props.regions}
                region={region}
              />
            </TabGallery>
          ))}
        </div>
      </div>
    </>
  );
};

export default AreaGalleryTab;
