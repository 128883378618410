import team1 from "../../../_assets/sound/team1.png";
import team2 from "../../../_assets/sound/team2.png";
import team3 from "../../../_assets/sound/team3.png";
import team4 from "../../../_assets/sound/team4.png";
import team5 from "../../../_assets/sound/team5.png";

export const team = [
  {
    name: "team1_name",
    role: "team1_t",
    text: "team1_p",
    imgUrl: team1
  },
  {
    name: "team2_name",
    role: "team2_t",
    text: "team2_p",
    imgUrl: team2
  },
  {
    name: "team3_name",
    role: "team3_t",
    text: "team3_p",
    imgUrl: team3
  },
  {
    name: "team4_name",
    role: "team4_t",
    text: "team4_p",
    imgUrl: team4
  },
  {
    name: "team5_name",
    role: "team5_t",
    text: "team5_p",
    imgUrl: team5
  }
];