import img1 from "../../../_assets/explore/img1.png";
import img2 from "../../../_assets/explore/img2.png";
import img3 from "../../../_assets/explore/img3.png";
import img4 from "../../../_assets/explore/img4.png";
import img9 from "../../../_assets/explore/img9.png";
import img10 from "../../../_assets/explore/img10.png";
import img11 from "../../../_assets/explore/img11.png";
import img12 from "../../../_assets/explore/img12.png";
import img13 from "../../../_assets/explore/img13.png";
import img14 from "../../../_assets/explore/img14.png";
import img17 from "../../../_assets/explore/img17.png";
import img18 from "../../../_assets/explore/img18.png";
import img19 from "../../../_assets/explore/img19.png";

export const exploreData = [
  {
    image: img1,
    title: "explore_item1_title",
    data: [{
      text: "explore_item1_text",
      link: "https://musiclab.chromeexperiments.com/Sound-Waves/"
    }],
    className: "explore-box-man",
    page: 4
  },
  {
    image: img2,
    title: "explore_item2_title",
    data: [{
      text: "explore_item2_text",
      link: "https://embassyofsound.org/halodzwieki"
    }],
    page: 16,
  },
  {
    image: img3,
    title: "explore_item3_title",
    data: [{
      text: "explore_item3_text",
      link: "https://www.youtube.com/watch?v=twBWepOd2as"
    }, {
      text: "explore_item3_text2",
      link: "https://youtu.be/XUXh-X1iveU"
    }],
    className: "explore-box-column explore-box-echolocation",
    page: 20
  },
  {
    image: img4,
    title: "explore_item4_title",
    data: [{
      text: "explore_item4_text",
      link: "http://www.wearehappyfrom.com/"
    }],
    className: "box-right explore-box-ballerina",
    page: 30
  },
  {
    image: img9,
    title: "explore_item9_title",
    data: [{
      text: "explore_item9_text",
      link: "https://semiconductor.withgoogle.com/"
    }, {
      text: "explore_item9_text2",
      link: "https://www.youtube.com/watch?v=ClHMj-wTieg"
    },
    {
      text: "explore_item9_text3",
      link: "https://embassyofsound.org/halodzwieki"
    }],
    className: "explore-box-column explore-box-music",
    page: 32
  },
  {
    image: img10,
    title: "explore_item10_title",
    data: [{
      text: "explore_item10_text",
      link: "https://www.youtube.com/watch?v=tyTlars-V1Y"
    }, {
      text: "explore_item10_text2",
      link: "https://embassyofsound.org/halodzwieki"
    },
    {
      text: "explore_item10_text3",
      link: "https://www.youtube.com/watch?v=5DBc5NpyEoo"
    }],
    className: "box-right explore-box-electronic-music",
    page: 36
  },
  {
    image: img11,
    title: "explore_item11_title",
    data: [{
      text: "explore_item11_text",
      link: "https://embassyofsound.org/halodzwieki"
    }],
    className: "explore-box-recording",
    page: 38
  },
  {
    image: img12,
    title: "explore_item12_title",
    data: [{
      text: "explore_item12_text",
      link: "http://haniarani.com"
    }],
    className: "box-right explore-box-student",
    page: 40
  },
  {
    image: img13,
    title: "explore_item13_title",
    data: [{
      text: "explore_item13_text",
      link: "https://www.youtube.com/c/nprmusic/playlists?view=50&sort=dd&shelf_id=11"
    }],
    className: "explore-box-concert",
    page: 42
  },
  {
    image: img14,
    title: "explore_item14_title",
    data: [{
      text: "explore_item14_text",
      link: "https://embassyofsound.org/halodzwieki"
    },
    {
      text: "explore_item14_text2",
      link: "https://vimeo.com/132408379"
    }],
    className: "box-right explore-box-architect",
    page: 48
  },
  {
    image: img17,
    title: "explore_item17_title",
    data: [{
      text: "explore_item17_text",
      link: "https://experiments.withgoogle.com/ai/bird-sounds/view/"
    },
    {
      text: "explore_item17_text2",
      link: "http://www.imaginarysoundscape.net"
    }],
    className: "explore-box-column explore-box-animal",
    page: 56
  },
  {
    image: img18,
    title: "explore_item18_title",
    data: [{
      text: "explore_item18_text",
      link: "/mountains"
    },
    {
      text: "explore_item18_text2",
      link: "https://embassyofsound.org/halodzwieki"
    }],
    className: "box-right explore-box-places",
    page: 60
  },
  {
    image: img19,
    title: "explore_item19_title",
    data: [{
      text: "explore_item19_text",
      link: "https://patternradio.withgoogle.com/"
    }],
    className: "explore-box-column explore-box-animal",
    page: 62
  }
];