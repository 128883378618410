import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const MobileMenu = () => {
  const { t, i18n } = useTranslation();
  const [openStatus, setOpenStatus] = useState(false);

  const toggleSubmenu = (state) => {
    setOpenStatus(state);
  };

  return (
    <div className="mobileMenuContent">
      <div className="mobileMenuContent__links">
        <div className="mobileMenuContent__links-link">
          <button
            className={`banner ${i18n.language === "pl" ? "active" : ""}`}
            onClick={() => i18n.changeLanguage("pl")}
          >
            pl
          </button>

          <div className="dot"></div>
          <button
            className={`banner ${i18n.language === "en" ? "active" : ""}`}
            onClick={() => i18n.changeLanguage("en")}
          >
            en
          </button>
        </div>

        <NavLink
          className="mobileMenuContent__links-link"
          activeClassName="mobileMenuContent__links-link-activeLink"
          exact={true}
          to="/"
        >
          {t("Home")}
        </NavLink>

        <div
          className="mobileMenuContent__links-submenu"
          onClick={() => toggleSubmenu(true)}
        >
          <span className="mobileMenuContent__links-link mobileMenuContent__links-projectLink">
            {t("projects")}
          </span>

          {openStatus && (
            <div className="mobileMenuContent__links-sublinks">
              <NavLink
                className="mobileMenuContent__links-sublinks-sublink"
                activeClassName="mobileMenuContent__links-sublinks-sublink-active"
                to="/arctic"
              >
                {t("teritory_arctic")}
              </NavLink>

              <NavLink
                className="mobileMenuContent__links-sublinks-sublink"
                activeClassName="mobileMenuContent__links-sublinks-sublink-active"
                to="/mountains"
              >
                {t("teritory_mountains")}
              </NavLink>

              <NavLink
                className="mobileMenuContent__links-sublinks-sublink"
                activeClassName="mobileMenuContent__links-sublinks-sublink-active"
                to="/sound"
              >
                {t("sound")}
              </NavLink>
            </div>
          )}
        </div>

        <NavLink
          className="mobileMenuContent__links-link"
          activeClassName="mobileMenuContent__links-link-activeLink"
          to="/about"
        >
          {t("About")}
        </NavLink>

        <NavLink
          className="mobileMenuContent__links-link"
          activeClassName="mobileMenuContent__links-link-activeLink"
          to="/contact"
        >
          {t("Contact")}
        </NavLink>
      </div>
    </div>
  );
};

export default MobileMenu;
