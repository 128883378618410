import React from "react";
import { useTranslation } from "react-i18next";

import Footer from "../../Footer/Footer";
import MenuOnPage from "../../Header/MenuOnPage";
import FundingLogo from "../Bookpage/FundingLogo";
import heroImg from "../../../_assets/explore/hero-img.jpg";
import ExploreItem from "./ExploreItem";

import {exploreData} from "./exploreConfig";

const Explore = () => {
  const { t } = useTranslation();

  return (
    <>
      <MenuOnPage />
      <div className="explore">
        <div className="explore__inside">
          <div className="explore__hero">
            <div className="explore__hero__text">
              <h1 className="sound__title">{t("explore_h1")}</h1>
              <p>{t("explore_p")}</p>
              <p><b>{t("explore_b")}</b></p>
            </div>
            <div className="explore__hero__img-container">
              <img src={heroImg} alt=""/>
            </div>
          </div>
          <div className="explore__items">
            {
              exploreData.map((item, index) => {
                return (
                  <ExploreItem
                    key={index}
                    image={item.image}
                    title={item.title}
                    text={item.data}
                    className={item.className}
                    page={item.page}
                  />
                )
              })
            }
          </div>
        </div>
        <FundingLogo/>
        <Footer />
      </div>
    </>
  );
};

export default Explore;
