import React from "react";
import classnames from "classnames";

import { IRegions, ITypeOfArea } from "../../../_helpers/models";
import { useTranslation } from "react-i18next";

interface IProps {
  area?: ITypeOfArea;
  region?: IRegions;
  selectedClassName: string;
  handleSelectOption: () => void;
}

const AreaOption = (props: IProps) => {
  const { area, region, handleSelectOption, selectedClassName } = props;
  const { t } = useTranslation();

  return (
    <>
      {region && (
        <div
          className={classnames(
            "areaSelect__options-option",
            selectedClassName
          )}
          onClick={handleSelectOption}
        >
          {t(region.name)}
        </div>
      )}

      {area && (
        <div
          className={classnames(
            "areaSelect__options-option",
            selectedClassName
          )}
          onClick={handleSelectOption}
        >
          {t(area.name)}
        </div>
      )}
    </>
  );
};

export default AreaOption;
