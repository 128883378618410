import React from "react";
import { Link } from "react-router-dom";

import Element from "./Element";

import { IPlace } from "../../../_helpers/models";
import { ConditionalWrapper } from "../../../_helpers/utils";

interface IProps {
  places: IPlace[];
}

const List = (props: IProps) => {
  const { places } = props;

  return (
      <div className="list">
        <ul>
          {places.map((place: IPlace, key) => (
            <li key={`places-${key}`} className='place'>
              <ConditionalWrapper
                condition={place.isAvailable}
                wrapper={children => <Link to={`/${place.path}`}>{children}</Link>}
              >
                <Element data={place} />
              </ConditionalWrapper>
            </li>
          ))}
        </ul>
      </div>
  );
};

export default List;
