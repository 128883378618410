import React from "react";
import { useTranslation } from "react-i18next";

import FundingLogo from "./FundingLogo";
import SoundSlider from "./SoundSlider";
import AudiobookSlider from "./AudiobookSlider";
import { MenuOnPage } from "../../Header";
import { Footer } from "../../Footer";

import bookImg from "../../../_assets/book/book.png";

const Book = () => {
  const { t } = useTranslation();

  return (
    <>
      <MenuOnPage />

      <div className="bookpage sound-area">
        <div className="bookpage__content">
          <div className="bookpage__book">
            <div className="bookpage__book__text">
              <h2 className="sound__title">{t("sound_book_t1")}</h2>
              <h3 className="sound__subtitle">{t("sound_book_t2")}</h3>
              <p className="sound__p bookpage__book__p">{t("sound_book_p")}</p>
              <a
                href="https://lhts.pl/produkt/halo-tu-dzwiek/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="sound__button">
                  {t("bookpage_buy_btn")}
                </button>
              </a>
            </div>
            <div className="bookpage__book__image">
              <div className="bookpage__book__image-wrapper">
                <img src={bookImg} alt="" />
              </div>
              <div className="bookpage__book__text-wrapper">
                <p>{t("bookpage_book_text")}</p>
                <a href="/zbadajto" className="sound__button">
                  {t("bookpage_explore_btn")}
                </a>
              </div>
            </div>
          </div>
          <AudiobookSlider />
          <SoundSlider />
        </div>
        <FundingLogo />
        <Footer />
      </div>
    </>
  );
};

export default Book;
