import { IPlace, Places } from "./_helpers/models";
import { arcticData, mountainsData, unavailableData } from "./_data";

import img_parks from "../src/_assets/list_parks.png";
import img_mountains from "../src/_assets/list_mountains.png";
import img_cities from "../src/_assets/list_cities.png";
import img_arctics from "../src/_assets/list_arctics.png";

import mountainsGlobe from "../src/_assets/areas/mountainsGlobe.png";
import arcticsGlobe from "../src/_assets/areas/arcticsGlobe.png";

export const places: IPlace[] = [
  {
    name: Places.arctic,
    image: img_arctics,
    path: "arctic",
    description:
      "Each place has a unque soundscape. The natural and cultural sounds awaken a sense of wonder that connect us to the qualities that these special places",
    globe: arcticsGlobe,
    data: arcticData,
    isAvailable: true,
  },
  {
    name: Places.mountains,
    image: img_mountains,
    path: "mountains",
    description:
      "Each place has a unque soundscape. The natural and cultural sounds awaken a sense of wonder that connect us to the qualities that these special places",
    globe: mountainsGlobe,
    data: mountainsData,
    isAvailable: true,
  },
  {
    name: Places.cities,
    image: img_cities,
    path: "cities",
    description:
      "Each place has a unque soundscape. The natural and cultural sounds awaken a sense of wonder that connect us to the qualities that these special places",
    globe: mountainsGlobe,
    data: unavailableData,
    isAvailable: false,
  },

  {
    name: Places.parks,
    image: img_parks,
    path: "parks",
    description:
      "Each place has a unque soundscape. The natural and cultural sounds awaken a sense of wonder that connect us to the qualities that these special places",
    globe: mountainsGlobe,
    data: unavailableData,
   isAvailable: false,
  },
];
