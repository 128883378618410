import React from "react";

import AboutElement from "./AboutElement";
import { Footer } from "../../Footer";
import { MenuOnPage } from "../../Header";

import { IAbout } from "./AboutData";
import { abouts } from "./AboutData";

const About = () => {
  return (
    <>
      <MenuOnPage />

      <div className="pageAbout">
        {abouts.map((fragment: IAbout, key) => (
          <AboutElement data={fragment} />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default About;
