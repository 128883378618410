import React from "react";
import { useTranslation } from "react-i18next";

import { places } from "../../data";
import { IPlace} from "../../_helpers/models";
import { ConditionalWrapper } from "../../_helpers/utils";

interface IProps {
  data: IPlace;
}
const AreaAdmission = (props: IProps) => {
  const { t } = useTranslation();
  const { data } = props;
 
  return (
    <section className="areaAdmission">
      <div className="areaAdmission__name">
        <h2>{t(data.name)}</h2>
      </div>

      <div className="areaAdmission__content">
        <ul>
          {places
            .filter((place: IPlace) => place.name !== data.name)
            .map((place: IPlace, key) => (
              <li key={key}>
                <ConditionalWrapper
                  condition={place.isAvailable}
                  wrapper={(children) => <a href={place.path}>{children}</a>}
                >
                  {t(place.name)}
                </ConditionalWrapper>
                <div className="areaAdmission__content-separator" />
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};

export default AreaAdmission;
