import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swiper from "swiper";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import PlayBtn from "../../PlayBtn/PlayBtn";

import about1 from "../../../_assets/book/about1.png";
import about2 from "../../../_assets/book/about2.png";
import about3 from "../../../_assets/book/about3.png";
import about4 from "../../../_assets/book/about4.png";

import sound1 from "../../../_assets/mp3/audio1.mp3";
import sound2 from "../../../_assets/mp3/audio2.mp3";
import sound3 from "../../../_assets/mp3/audio3.mp3";
import sound4 from "../../../_assets/mp3/audio4.mp3";

import "swiper/css/swiper.css";

const slides = [{
  img: about1,
  sound: sound1
}, {
  img: about2,
  sound: sound2
}, {
  img: about3,
  sound: sound3
}, {
  img: about4,
  sound: sound4
}];

const SoundSlider = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    new Swiper(".about__slider__container", {
      navigation: {
        nextEl: ".about__slider__arrow-next",
        prevEl: ".about__slider__arrow-prev",
      },
      on: {
        slideNextTransitionStart: () => {
          setCurrentSlide((currentSlide) => currentSlide + 1);
        },
        slidePrevTransitionStart: () => {
          setCurrentSlide((currentSlide) => currentSlide - 1);
        },
      },
    });

  }, []);

  const renderSlides = () => {
    return slides.map((item, index) => {
      return (
        <div className="swiper-slide" key={index}>
          <div className="about__slider__slide-image-wrapper">
            <img src={item.img} alt="" />
            <div className="about__slider__play">
              <PlayBtn url={item.sound} stopPlaying={currentSlide !== index+1} />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="about__slider">
      <div className="about__slider__inside">
        <h2 className="sound__title">{t("bookpage_about")}</h2>
      </div>
      <div className="about__slider__carousel">
        <div className="about__slider__carousel-container">
          <div className="about__slider__container swiper-container">
            <div className="swiper-wrapper">
              {
                renderSlides()
              }
            </div>

            <div className="about__slider__navigation">
              <span className="about__slider__navigation__arrow about__slider__arrow-prev swiper-button-prev">
                <ArrowBackIcon />
              </span>
              <span className="about__slider__navigation__current-slide">
                {currentSlide}/4
              </span>
              <span className="about__slider__navigation__arrow about__slider__arrow-next swiper-button-next">
                <ArrowForwardIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundSlider;
