import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swiper from "swiper";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import Soundcloud from "./Soundcloud";

import "swiper/css/swiper.css";

const AudioBook = () => {
  const { t } = useTranslation();
  const [currentSlideAudio, setCurrentSlideAudio] = useState(1);

  useEffect(() => {
    new Swiper(".audiobook__container", {
      navigation: {
        nextEl: ".audiobook__next",
        prevEl: ".audiobook__prev",
      },
      on: {
        slideNextTransitionStart: () => {
          setCurrentSlideAudio((currentSlideAudio) => currentSlideAudio + 1);
        },
        slidePrevTransitionStart: () => {
          setCurrentSlideAudio((currentSlideAudio) => currentSlideAudio - 1);
        },
      },
    });

  }, []);

  return (
    <div className="audiobook">
      <div className="audiobook__inside">
        <h2 className="sound__title">{t("bookpage_audiobook")}</h2>
        <div className="audiobook__audio">
          <div className="audiobook__playlist">
            <div className="swiper-container audiobook__container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <Soundcloud
                    title="Playlist I"
                    url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1132871995&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                  />
                </div>
                <div className="swiper-slide">
                  <Soundcloud
                    title="Playlist II"
                    url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1132879339&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                  />
                </div>
                <div className="swiper-slide">
                  <Soundcloud
                    title="Playlist III"
                    url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1153312546&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                  />
                </div>
                <div className="swiper-slide">
                  <Soundcloud
                    title="Playlist IV"
                    url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1153312948&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                  />
                </div>
                <div className="swiper-slide">
                  <Soundcloud
                    title="Playlist V"
                    url="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1153313413&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                  />
                </div>
              </div>
            </div>
            <div className="audiobook__navigation">
              <span className="swiper-button-prev audiobook__prev audiobook__navigation__arrow">
                <ArrowBackIcon />
              </span>
              <span className="audiobook__navigation__current-slide">
                {currentSlideAudio}/5
              </span>
              <span className="swiper-button-next audiobook__next audiobook__navigation__arrow">
                <ArrowForwardIcon />
              </span>
            </div>
            <div className="audiobook__download-wrapper">
              <a
                className="audiobook__download"
                href="https://www.dropbox.com/sh/lcxkjaq0f5uesyx/AAAxqkpouOx5pbVV0TwWZyvJa?dl=1"
              >
                DOWNLOAD
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioBook;
