import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTerritoryActionsContext } from "../../../context/Terrritory.context";

import { ITypeOfArea } from "../../../_helpers/models";
import { ReactComponent as Pin } from "../../../_assets/areas/PIN.svg";

interface IProps {
  area: ITypeOfArea;
  areaPath: string;
  handleLocation: () => void;
}

const AreaLocation = (props: IProps) => {
  const { t } = useTranslation();
  const { area, areaPath } = props;
  const { setTerritoryData } = useTerritoryActionsContext();
  const history = useHistory();

  const handleTerritory = () => {
    setTerritoryData(area.territory);
    history.push(`/project/${areaPath}/${area.slug}`);
  };

  return (
    <div
      className="areaGlobe__globe-position-location"
      style={{
        position: "absolute",
        top: area.locationY,
        left: area.locationX,
      }}
    >
      <Pin
        onMouseOver={props.handleLocation}
        onClick={handleTerritory}
        className="areaGlobe__globe-position-location-pin"
      />
      <div
        onMouseOver={props.handleLocation}
        onClick={handleTerritory}
        className="areaGlobe__globe-position-location-tooltip"
      >
        <p>{t(area.name)}</p>
      </div>
    </div>
  );
};

export default AreaLocation;
