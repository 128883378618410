import React, { useCallback, useState } from "react";
import { HamburgerSqueeze } from "react-animated-burgers";

import { Menu, MobileMenu } from "./";

import logoMenu from "../../_assets/logo-header.svg";

const MenuOnPage = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleButton = useCallback(
    () => setIsActive((prevState) => !prevState),
    []
  );

  return (
    <>
      <div className="staticMenu">
        <Menu />
      </div>

      <div className="menuMobile">
        <div className="menuMobile__content">
          <a href="/">
            <img alt="menu logo Embassy" src={logoMenu}></img>
          </a>

          <HamburgerSqueeze
            buttonColor="white"
            barColor="#55BEC3"
            buttonWidth={32}
            className="menuMobile__content-icon"
            {...{ isActive, toggleButton }}
          />
        </div>
      </div>

      {isActive && <MobileMenu />}
    </>
  );
};

export default MenuOnPage;
