import about1 from "./about1.png";
import about2 from "./about2.png";
import about3 from "./about3.png";
import about4 from "./about4.png";
import about5 from "./about5.png";
import logo from "./logoLHTS.png";

export const aboutImages = {
  about1: about1,
  about2: about2,
  about3: about3,
  about4: about4,
  about5: about5,
  logo: logo,
};
