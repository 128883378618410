import React from "react";
import { useTranslation } from "react-i18next";

import List from "../Homepage/List/List";
import Testimonials from "../Homepage/Testimonials/Testimonials";
import Partners from "../Homepage/Slider/Partners";
import { MenuOnPage } from "../Header";
import { Footer } from "../Footer";

import { places } from "../../data";
import logo from "../../_assets/vertical_1Black.svg";
import book_image from "../../_assets/Book_work in progress.png";
import headerImg from "../../_assets/bg_homepage.png";

const Homepage = () => {
  const { t } = useTranslation();

  return (
    <>
      <MenuOnPage />

      <div className="homepage">
        <section className="homepage__content">
          <div className="header">
            <div className="header__inside">
              <div className="header__inside-content">
                <div className="header__inside-content-grayBackground"></div>
                <img src={logo} alt="logo" />
                <>
                  <h2>
                    {t("homepage_slogan_1")} <br />
                    {t("homepage_slogan_2")}
                  </h2>
                  <div className="header-separator">
                    <div className="header-separator-line"></div>
                  </div>
                </>
              </div>

              <div className="header__img">
                <img src={headerImg} alt="header" />
              </div>
            </div>
          </div>

          <div className="background_section">
            <div className="admission">
              <h2 className="admission__title">
                {t("homepage_admission_title")}
                <br />
                {t("homepage_admission_subtitle")}
                <br />
              </h2>
              <p className="admission__content">
                {t("homepage_admission_content")}
              </p>
            </div>
            <List places={places} />
          </div>

          <div className="books">
            <div className="books__inside">
              <div className="books__inside-element">
                <div className="books__inside-element-content">
                  <img src={book_image} alt="Book" />
                </div>
              </div>
              <div className="books__inside-element">
                <div className="books__inside-element-content">
                  <h2>{t("homepage_sa_title")}</h2>
                  <h3>{t("homepage_sa_subtitle")}</h3>
                  <p>{t("homepage_sa_content")}</p>
                  <a href="/sound" className="books__inside-button">{t("explore")}</a>
                </div>
              </div>
            </div>
          </div>

          <Testimonials />

          <Partners />
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Homepage;
