import React from "react";
import { render } from "react-dom";
import "../src/scss/style.scss";
import App from "./containers/App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../src/_helpers/theme";

render(
  <MuiThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
