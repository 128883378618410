import React from "react";
import logo1 from "../../../_assets/book/logo1.svg";
import logo2 from "../../../_assets/book/logo2.svg";

interface ILogoProps {
  title?: string;
}

const FundingLogo = (props: ILogoProps) => {

  return (
    <div className="funding-logo">
      <div className="funding-logo__inside">
        <h2 className="sound__title">{props.title}</h2>
        <div className="funding-logo__logos-wrapper">
          <div className="funding-logo__logo1">
            <div className="funding-logo__logo1__img">
              <img src={logo1} alt="" />
            </div>
            <div className="funding-logo__separator"></div>
          </div>
          <div className="funding-logo__logo2">
            <img src={logo2} alt="" />
            
          </div>
          <div className="funding-logo__text">
            <p>
              Dofinansowano ze środków Ministra Kultury i Dziedzictwa Narodowego pochodzących z Funduszu Promocji Kultury,
              uzyskanych z dopłat ustanowionych w grach objętych monopolem 4 państwa, zgodnie z art. 80 ust. 1 ustawy z dnia
              19 listopada 2009 r. o grach hazardowych, w ramach programu „Muzyczny ślad”, realizowanego przez Instytut
              Muzyki i Tańca
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingLogo;
