import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { CircularProgress } from "@material-ui/core";

import MobileMenuOnPage from "../Header/MenuOnPage";
import { Footer } from "../Footer";
import { AreaAdmission, AreaGlobe, AreaTabs } from "../Areas";

import { places } from "../../data";
import { IPlace, IData } from "../../_helpers/models";
import DataContext from "../../DataContext";

const Area = () => {
  const location = useLocation();
  const [key, setKey] = useState<string>();
  const [area, setArea] = useState<IPlace>();
  const [data, setData] = useState<IData>();

  useEffect(() => {
    if (location.pathname !== "") setKey(location.pathname.substr(1));
  }, [location]);

  useEffect(() => {
    setArea(places.find((p: IPlace) => p.path === key));
  }, [key]);

  useEffect(() => {
    setData(area?.data);
  }, [area]);

  return (
    <DataContext.Provider value={area}>
      <>
        <MobileMenuOnPage />

        <div className="area">
          {area && data ? (
            <>
              <AreaAdmission data={area} />
              <AreaGlobe
                data={area}
                areas={data.typeOfArea}
              />
              <AreaTabs regions={data.regions} />
            </>
          ) : (
            <>
              <CircularProgress />
            </>
          )}
        </div>

        <Footer />
      </>
    </DataContext.Provider>
  );
};

export default Area;
