import React from "react";

interface SoundcloudProps {
  url: string;
  title: string;
}

const SoundCloud = (props: SoundcloudProps) => {

  return (
    <iframe
      title={props.title}
      width="100%"
      height="450"
      src={props.url}
    ></iframe>
  );
};

export default SoundCloud;
