import React from "react";
import { useTranslation } from "react-i18next";

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

import logo from "../../_assets/vertical_1.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      
      <div className="footer__socialmedia">
        <a href="https://www.facebook.com/Embassy-of-Sound-EoS-108420331064748" target="_blank" rel="noopener noreferrer"><FacebookIcon className="footer__socialmedia-icon"/></a>
        <a href="https://www.instagram.com/embassyofsound_eos"><InstagramIcon className="footer__socialmedia-icon" /></a>
      </div>

      <div className="footer__content">
        <div className="footer__content-inside">
          <div className="footer__content-inside-offset"></div>
          <div className="footer__content-inside-logo">
            <img src={logo} alt="logo" />
            <p>{t("footer_slogan")}</p>
          </div>
          <div className="footer__content-inside-separator"></div>
        </div>
        <div className="footer__content-inside-separatorMobile"></div>

        <div className="footer__content-list">
          <div className="footer__content-list-element">
            <h3>{t("projects")}</h3>
            <ul>
              <li>
                <a href="/arctic">{t("soundspace")}</a>
              </li>

              <li>
                <a href="/book">{t("book")} "Halo! Tu dźwięk"</a>
              </li>

              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://embassyofsound.org/halodzwieki/">{t("game")} "Halodźwięki" </a>
              </li>
            </ul>
          </div>

          <div className="footer__content-list-element">
            <h3>{t("about")}</h3>
            <ul>
              <li>
                <a href="/about">{t("embassy")} </a>
              </li>         

              <li>
                <a href="/contact">{t("contact_us")}</a>
              </li>
            </ul>
          </div>

          <div className="footer__content-list-element">
            <h3>{t("term_of_use")}</h3>
            <ul>
              <li>
                <a href="/">{t("download_pdf")}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
