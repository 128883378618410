import { withStyles } from "@material-ui/core/styles";
import { Tabs } from "@material-ui/core";

export const StyledTabs = withStyles({
  root: {
    boxShadow: "none",
    elevation: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);
