import HimalayasMap from "./gallery/HimalayasMap";
import map from "../../_assets/areas/gallery/himalayasMap.svg";
import logo from "../../_assets/areas/logoAoA.png";

import {
  Bhimtang,
  Caravan,
  Dharmasala,
  Harvest,
  Jungle,
  Lho,
  Tibet,
  Sunrise,
  Pungyen,
  Larkya
} from "./gallery/Manaslu";

export const mountainsImages = {
  mapC: HimalayasMap,
  map: map,
  logo: logo,
  photos: [
    { id: 0, src: Tibet, desc: "gallery_himalaya_tibet" },
    { id: 1, src: Caravan, desc: "gallery_himalaya_caravan" },
    { id: 2, src: Harvest, desc: "gallery_himalaya_harvest" },
    { id: 3, src: Jungle, desc: "gallery_himalaya_jungle" },
    { id: 4, src: Sunrise, desc: "gallery_himalaya_sunrise" },
    { id: 5, src: Lho, desc: "gallery_himalaya_lho" },
    { id: 6, src: Pungyen, desc:  "gallery_himalaya_pungyen" },   
    { id: 7, src: Dharmasala, desc: "gallery_himalaya_dharmasala" },
    { id: 8, src: Larkya, desc: "gallery_himalaya_larkya"},
    { id: 9, src: Bhimtang, desc: "gallery_himalaya_Bhimtang" }
  ],
};

