import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import ScrollAnimation from "react-animate-on-scroll";

import { IAbout } from "./AboutData";
import "animate.css/animate.css";

interface IProps {
  data: IAbout;
}

const AboutElement = (props: IProps) => {
  const { data } = props;
  const { t } = useTranslation();

  const durationHeader = 2;
  const appearHeader = "fadeInDown";

  const cx = classNames.bind(data.styles);

  const reverseClass = cx({
    positionClass: true,
    reverse: data.isReverse,
  });

  const descriptionClass = cx({
    descriptionClass: true,
    "about__content-contents-description-break": data.isTextDouble,
  });

  return (
    <div className="offset" id={data.id}>
      <div className={data.rectangleClass} />
      <div className={reverseClass}>
        <div className="about__content">
          <img className={data.imageClass} src={data.image} alt="About" />
        </div>

        <div className="about__content">
          <div className="about__content-contents">
            {data.isTitle && (
              <ScrollAnimation
                animateIn={appearHeader}
                duration={durationHeader}
              >
                <h2 className={data.headerClass}>{t(data.title)}</h2>
              </ScrollAnimation>
            )}

            {data.isSubtitle && (
              <ScrollAnimation
                animateIn={appearHeader}
                duration={durationHeader}
              >
                <h3>{t(data.subtitle)}</h3>
              </ScrollAnimation>
            )}

            <p className={descriptionClass}>{t(data.content)}</p>
            <p className={descriptionClass}>{t(data.contentBreak)}</p>

            {data.isLink && (
              <span className={"generic__linklike_subtext"}>
                {t("about_slogan")}
              </span>
            )}
            {data.isLogo && <img src={data.logo} alt="logo" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutElement;
