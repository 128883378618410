import React, { useEffect } from "react";
import Swiper from "swiper";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import "swiper/css/swiper.css";
import { IAreaGallery, IRegions } from "../../../_helpers/models";
import { useTranslation } from "react-i18next";

interface IProps {
  regions: IRegions[];
  region: IRegions;
  index: number;
}

const AreaGallery: React.FC<IProps> = ({ regions, region }) => {
  const { t } = useTranslation();

  useEffect(() => {
    new Swiper(".areaGallery__container", {
      pagination: {
        el: ".areaGallery__pagination",
        type: "bullets",
        clickable: true,
        bulletClass: "areaSlider__areaBullet",
        bulletActiveClass: "areaSlider__areaBullet--active",
        modifierClass: "areaSlider__areaBullet--",
      },
      parallax: true,
      effect: "fade",
      loop: true,
      navigation: {
        nextEl: ".slider__button--next",
        prevEl: ".slider__button--prev",
      },
      on: {
        slideChange: function () {},
      },
    });
  }, []);

  return (
    <>
      <div className="areaGallery">
        <div className="areaGallery__content">
          <div className="areaGallery__content-slider">
            <div className="areaGallery__container swiper-container">
              <div className="areaGallery__container-wrapper swiper-wrapper">
                {region.gallery.map(
                  (el: IAreaGallery, key: number, array: IAreaGallery[]) => (
                    <div
                      key={key}
                      className="areaGallery__sliders swiper-slide"
                    >
                      <div className="slideGallery__imageMain--container">
                        <div
                          className="slideGallery__imageMain"
                          data-swiper-parallax="-200"
                          style={{ backgroundImage: `url(${el.src})` }}
                        ></div>
                      </div>

                      <div className="slideGallery__imageNext--next">
                        <div
                          className="slideGallery__imageNext"
                          data-swiper-parallax="-300"
                          style={{
                            backgroundImage: `url(${
                              array[el.id + 1]?.src ?? array[0]?.src
                            })`,
                          }}
                        ></div>
                      </div>
                      <div
                        className="areaTabs__content-footnotes"
                        data-swiper-parallax="0"
                      >
                        <span className="areaTabs__content-footnotes-name">
                          - {t(region.name)}
                        </span>
                        <span className="areaTabs__content-footnotes-separator">
                          /
                        </span>
                        <span className="areaTabs__content-footnotes-subname">
                          {t(region.gallery[el.id].desc)}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="swiper-pagination"></div>
              <div className="slider__buttons slider__buttons--vertical slider__buttons--gallery">
                <div className="slider__button slider__button--prev">
                  <div className="slider__icon slider__icon--white">
                    <ArrowBackIcon
                      style={{
                        display: "block",
                        color: "#fff",
                        fontSize: "34px",
                      }}
                    />
                  </div>
                </div>
                <div className="slider__button slider__button--next">
                  <div className="slider__icon slider__icon--white">
                    <ArrowForwardIcon
                      style={{
                        display: "block",
                        color: "#fff",
                        fontSize: "34px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="areaGallery__pagination swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AreaGallery;
