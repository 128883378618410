import React from "react";
import { useTranslation } from "react-i18next";

import { ITestimonals } from "../../Homepage/Testimonials/dataTestimonials";
import testimonialImg from "../../../_assets/testimonial_background.png";

interface IProps {
  data: ITestimonals;
}

const Testimonials = (props: IProps) => {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <div className="testimonial">
      <div className="testimonial__content">
        <div className="testimonial__content-description">
          <img src={testimonialImg} alt="Testimonials" />
          <p>{t(data.description)}</p>
        </div>
        <div className="testimonial__content-author">
          <h4 className="testimonial__content-author-name">
            <span className="testimonial__content-author-name-chart">-</span>
            <span className="testimonial__content-author-name-title">
              {data.title}
            </span>
              {data.name}
          </h4>
          <div className="testimonial__content-author-separator">/</div>
          <h4>{data.profession}</h4>
        </div>
      </div>
      <div className="testimonial__image--container">
        <div
          className="testimonial__image"
          data-swiper-parallax="-200"
          style={{ backgroundImage: `url(${data.photo})` }}
        ></div>
      </div>
    </div>
  );
};

export default Testimonials;
