import React from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import useGoogleAnalytics from "../../../analyticsHook";

import { Footer } from "../../Footer";

import heroImg from "../../../_assets/sound/bg_sound.png";
import bookImg from "../../../_assets/sound/book.png";
import gameImg from "../../../_assets/sound/game.png";

import { team } from "./teamConfig";
import { MenuOnPage } from "../../Header";

function HaloGA() {
  useGoogleAnalytics();
  const { t } = useTranslation();

  return (
    <div>
      <ReactGA.OutboundLink
        eventLabel="Halo Dzwięki"
        to="https://embassyofsound.org/halodzwieki/"
        target="_blank"
        rel="noopener noreferrer"
        className="sound__button"
      >
        {t("sound_btn")}
      </ReactGA.OutboundLink>
    </div>
  );
}

const SoundArea = () => {
  const { t } = useTranslation();

  const renderTeam = () => {
    return team.map((item, id) => {
      return (
        <div className="team__item__wrapper" key={id}>
          <div className="team__item">
            <h3 className="team__item__title">{t(item.name)}</h3>
            <div className="team__item__content">
              <img src={item.imgUrl} alt="" />
              <div className="team__item__text">
                <h4 className="team__item__text-title">{t(item.role)}</h4>
                <p>{t(item.text)}</p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <MenuOnPage />

      <div className="sound-area">
        <div>
          <div className="hero">
            <div className="hero__inside">
              <div className="hero__content">
                <div className="hero__content__title-wrapper">
                  <h2 className="sound__title hero__sound__title">
                    {t("sound_hero_t1")}
                  </h2>
                  <h3 className="sound__subtitle hero__sound__subtitle">
                    {t("sound_hero_t2")}
                  </h3>
                </div>
                <div className="hero__content__text-wrapper">
                  <p>{t("sound_hero_p1")}</p>
                  <p>
                    {t("sound_hero_p2")}
                    <b>{t("sound_hero_p2_b")}</b>
                  </p>
                </div>
              </div>

              <div className="hero__img">
                <img src={heroImg} alt="header" />
              </div>
            </div>
          </div>

          <div className="section-images">
            <div className="section-images__book">
              <div className="section-images__book__image">
                <img className="section-images__image" src={bookImg} alt="" />
              </div>
              <div className="section-images__book__content">
                <h2 className="sound__title section-images__book__title">
                  {t("sound_book_t1")}
                </h2>
                <h3 className="sound__subtitle section-images__book__subtitle">
                  {t("sound_book_t2")}
                </h3>
                <p className="sound__p">{t("sound_book_p")}</p>
                <a className="sound__button" href="/book">
                  {t("sound_btn")}
                </a>
              </div>
            </div>
            <div className="section-images__game">
              <div className="section-images__game__content">
                <h2 className="sound__title section-images__game__title">
                  {t("sound_game_t")}
                </h2>
                <p className="sound__p">
                  {t("sound_game_p")}
                  <br />
                  <b>{t("sound_game_b")}</b>
                </p>

                <HaloGA />
              </div>
              <div className="section-images__game__image">
                <img className="section-images__image" src={gameImg} alt="" />
              </div>
            </div>
          </div>

          <div className="team">
            <div className="team__content">
              <h2 className="sound__title team__title">{t("team_t")}</h2>
              <div>{renderTeam()}</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SoundArea;
