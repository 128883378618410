import { aboutImages } from "../../../_assets";

export interface IAbout {
  id?: string;
  title: string;
  subtitle: string;
  content: string;
  contentBreak: string;
  image: string;
  link: string;
  fade: string;
  isTitle: boolean;
  isSubtitle: boolean;
  isLink: boolean;
  isReverse: boolean;
  imageClass: string;
  rectangleClass: string;
  headerClass: string;
  isLogo: boolean;
  isTextDouble?: boolean;
  logo: string;
  styles: Record<string, string>
}

const fadeLeft = "fadeInLeft";
const fadeRigth = "fadeInRight";

export const abouts: IAbout[] = [
  {
    title: "about_h_1",
    subtitle: "embassy",
    link: "about_slogan",
    isTitle: true,
    isSubtitle: true,
    isLink: true,
    content: "about_p_1",
    contentBreak: "",
    image: aboutImages.about1,
    fade: fadeRigth,
    isReverse: false,
    imageClass: "about__content-image1",
    rectangleClass: "offset__background-background1",
    headerClass: "about__content-contents-headerDif",
    isLogo: false,
    logo: "",
    styles: {
      positionClass: "about",
      descriptionClass: "about__content-contents-descriptionDif",
    }
  },

  {
    title: "about_h_2",
    subtitle: "",
    link: "",
    isTitle: false,
    isSubtitle: false,
    isLink: false,
    content: "about_p_2",
    contentBreak: "",
    image: aboutImages.about4,
    fade: fadeLeft,
    isReverse: true,
    imageClass: "about__content-image4",
    rectangleClass: "offset__background-background4",
    headerClass: "about__content-contents-header",
    isLogo: false,
    logo: "",
    styles: {
      positionClass: "about about--alignStart",
      descriptionClass: "about__content-contents-description",
    }
  },

  {
    title: "about_h_3",
    id: "team",
    subtitle: "",
    link: "",
    isTitle: true,
    isSubtitle: false,
    isLink: false,
    content: "about_p_3",
    contentBreak: "",
    image: aboutImages.about5,
    fade: fadeRigth,
    isReverse: false,
    imageClass: "about__content-image5",
    rectangleClass: "offset__background-background5",
    headerClass: "about__content-contents-headerTeam",
    isLogo: false,
    isTextDouble: true,
    logo: "",
    styles: {
      positionClass: "about about--alignEnd",
      descriptionClass: "about__content-contents-descriptionDif",
    }
  },
];
