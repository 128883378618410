import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { ParallaxProvider } from "react-scroll-parallax";
import { StickyContainer, Sticky } from "react-sticky";

import Territory from "../components/Video/Territory";
import { TerritoryContextProvider } from "../context/Terrritory.context";
import {
  About,
  Area,
  Book,
  Contact,
  Homepage,
  SoundArea,
  Explore,
} from "../components/Pages";

import logo from "../_assets/logo-header.svg";
import { Menu } from "../components/Header";

function App(): JSX.Element {
  return (
    <StickyContainer>
      <ParallaxProvider>
        <TerritoryContextProvider>
          <div className="App">
            <Router>
              <ScrollToTop>
                <Sticky topOffset={450}>
                  {({ style }) => (
                    <div
                      style={{
                        ...style,
                        borderBottom:
                          window.scrollY > 450
                            ? "2px solid #55BEC3"
                            : "2px solid #55BEC3",
                        opacity: window.scrollY > 450 ? "1" : "0",
                        width: "100%",
                        left: 0,
                        backgroundColor: "#fff",
                      }}
                      className="sticky"
                    >
                      <div className="sticky__inside">
                        <div
                          style={{
                            opacity: window.scrollY > 450 ? "1" : "0",
                          }}
                          className="sticky__inside-logo"
                        >
                          <a href="/">
                            <img src={logo} alt="Embassy of Sound" />
                          </a>
                        </div>

                        <Menu />
                      </div>
                    </div>
                  )}
                </Sticky>

                <Switch>
                  <Route path="/" exact component={Homepage} />
                  <Route path="/about" component={About} />
                  <Route path="/project/:area/:name" component={Territory} />
                  <Route path="/contact" component={Contact} />
                  <Route exact path="/cities" component={Area} />
                  <Route exact path="/mountains" component={Area} />
                  <Route exact path="/parks" component={Area} />
                  <Route exact path="/arctic" component={Area} />
                  <Route exact path="/sound" component={SoundArea} />
                  <Route exact path="/book" component={Book} />
                  <Route exact path="/zbadajto" component={Explore} />
                  <Route component={NoMatchRoute} />
                </Switch>
              </ScrollToTop>
            </Router>
          </div>
        </TerritoryContextProvider>
      </ParallaxProvider>
    </StickyContainer>
  );
}

const NoMatchRoute = () => <div>404 Page</div>;

export default App;
