import React from "react";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import arrRight from "../../../_assets/arr-right.svg";

interface IText {
  text: string;
  link?: string;
}

interface IProps {
  image: string;
  page: number;
  title: string;
  text: IText[];
  inverted?: boolean;
  className?: string;
}

const ExploreItem = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classnames("explore__item", [props.className])}>

      <div className="explore__item__box">
        <span className="explore__item__page">strona {props.page}</span>
        <span className="explore__item__img">
          <img src={props.image} alt=""/>
        </span>
        <div className="explore__item__box__content">
          <div className="explore__item__header">{t(props.title)}</div>
          <div className="explore__items-wrapper">
            <span className="explore__item__page explore__item__page__mobile">strona {props.page}</span>
            {
              props.text.map((item, index) => {
                return (
                  <a 
                    key={index}
                    href={item.link ? item.link : undefined}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classnames("explore__item__link-box", {
                    "explore__item__link-box-clickable": item.link
                  })}
                  >
                    <Trans
                      components={[<span></span>]} 
                      defaults={t(item.text)}
                    />
                    <span className="btn-link">
                      <img src={arrRight} alt=""/>
                    </span>
                  </a>
              )})
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreItem;
